import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'


const queryClient = new QueryClient()

export default function App() {

return(

  <>
      <QueryClientProvider client={queryClient}>
        <Notification />
      </QueryClientProvider>
    </>
  );
}





function Notification() {
  const { isLoading, error, data } = useQuery('repoData', () =>
    fetch('https://hptu-plus-notification.pages.dev/api/notify.json').then(res =>
      res.json()
    )
  )

  if (isLoading) return 'Loading...'

  if (error) return 'An error has occurred: ' + error.message

  return (
    <div>


      {data.map((notify) => (

        <>

          <button className='btn shadow w-100 p-4 mt-3 border' onClick={()=>{OpenType(notify)}}>

            <span className='h6 text-primary'>
              <b>
                {notify.title}
              </b></span>
            <br />
            <span className='text-orange'>
              <i>
                {notify.description}
              </i>
            </span>

          </button>
          <date className='bg-secondary text-white'>
              {notify.date}
          </date>
          <br />
        </>


      ))}


    </div>
  )
}

function OpenType(props){
  if(props.type == "web"){
    window.Android.openWebContent(props.url)
  }else{
    window.Android.openPDF(props.url)
  }
}